<template>
  <div>
    <div class="flex flex-wrap mt-4 table-main">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5">
        <div class="rounded-t mb-4 py-3 border-0">
          <div class="flex flex-wrap items-center" style="justify-content: space-between;">
            <div class="relative px-4 max-w-full">
              <h3 class="font-semibold text-lg text-blueGray-700">
                Danh sách giao dịch Cổng
              </h3>
            </div>
          </div>
        </div>
        <div class="bg-white block w-full overflow-x-autoflex-auto py-10 pt-0">
          <alert v-if="alertMessage" :message="alertMessage" :type="alertType"></alert>
          <form>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mb-3 mt-3 items-center" style="grid-row-gap: 10px;">
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Mã giao dịch
                  </label>
                  <input
                      type="text"
                      class=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="formData.payment_no"
                  >
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Tên Merchant
                  </label>
                  <multiselect
                      v-model="formData.merchant_key"
                      :options="merchantOptions"
                      placeholder="Vui lòng nhập..."
                      :searchable="true"
                      :loading="merchantLoading"
                      :options-limit="50"
                      open-direction="bottom"
                      track-by="name"
                      label="name"
                      @search-change="searchMerchant"
                  ></multiselect>
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                   Từ Ngày giao dịch
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="formData.date_from"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Đến Ngày giao dịch
                  </label>
                  <input
                      type="date"
                      lang="vi"
                      v-model="formData.date_to"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Số thẻ/STK </label>
                  <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="formData.card_number"
                  >
                </div>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  >
                    Số tiền giao dịch </label>
                  <input
                      type="number"
                      min="0"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="formData.amount"
                  >
                </div>
              </div>
            </div>
            <div class="max-w-sm w-full lg:max-w-full lg:flex mt-3 justify-end">
              <button @click="handleSearch"
                      class="hover:bg-emerald-600 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button">
                <i class="fas fa-search"></i> Tìm kiếm
              </button>
              <button @click="handleDownloadFile"
                      class="hover:bg-lightBlue-600 bg-lightBlue-500 text-white font-bold uppercase text-xs px-4 py-3 rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150 relative"
                      type="button">
                <div v-if="isExporting" style="height: 100%; width: 100%; cursor: wait">
                  <i style="position: relative; z-index: 1;">Đang xuất {{ progressExport }}%</i>
                  <div class="progress-export" :style="{ width: `${progressExport}%` }"></div>
                </div>
                <div v-else>
                  <i class="fas fa-download"></i> Xuất file
                </div>
              </button>
            </div>
          </form>

        </div>
        <div class="block w-full overflow-x-auto relative" style="width: 100%; height: 100%">
          <!-- Projects table -->
          <table class="items-center w-full bg-transparent border-collapse list-config">
            <thead>
            <tr>
              <th
                  class="text-center width10 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                STT
              </th>
              <th
                  class="text-center width10 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                Mã giao dịch
              </th>

              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Thời gian khởi tạo
              </th>
              <th
                  :class="'text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold'"
              >
                Thời gian cập nhật
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Tên Merchant
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Giá trị giao dịch
              </th>

              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                PTTT
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Ngân hàng/Số thẻ-Stk
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                IP
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 width20 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Trạng thái
              </th>
              <th
                  class="bg-blueGray-50 text-blueGray-500 border-blueGray-100 px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              >
                Chi tiết
              </th>
            </tr>
            </thead>
            <tbody>
            <tr :style="{backgroundColor: index % 2 === 0 ? '#ecf0f5' : ''}" v-bind:key="index"
                v-for="(transaction, index) in data_transaction">
              <td class="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ index + 1 }}
              </td>
              <td class="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.payment_no }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.created_at }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.updated_at }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.merchant_name || "-" }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.amount || "0" }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.meta_data && transaction.meta_data.method ? changeNameMethod(transaction.meta_data.method) : "-" }}
              </td>

              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" v-if="transaction.meta_data.method === 'CREDIT_CARD'">
                {{transaction.meta_data.card_brand ?? ""}}
                {{ transaction.meta_data && transaction.meta_data.card_number ? maskedCardNumber(transaction.meta_data.card_number) : "" }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" v-if="transaction.meta_data.method === 'COLLECTION'">
                {{transaction.meta_data.bank_code ?? ""}}
                {{ transaction.meta_data && transaction.meta_data.card_number ? maskedCardNumber(transaction.meta_data.card_number) : "" }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" v-if="transaction.meta_data.method === 'WALLET'">
                {{ transaction.meta_data && transaction.meta_data.card_number ? maskedCardNumber(transaction.meta_data.card_number) : "-" }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" v-if="transaction.meta_data.method === 'ATM_CARD'">
                {{transaction.meta_data.bank_code}}
                {{ transaction.meta_data && transaction.meta_data.card_number ? maskedCardNumber(transaction.meta_data.card_number) : "" }}
              </td>
              <td class="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.meta_data && transaction.meta_data.client_ip ? transaction.meta_data.client_ip : "-" }}
              </td>

              <td class="text-center border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {{ transaction.status.label }}
              </td>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <router-link :to="'/admin/config-fee/transaction/detail/' + transaction.id" style="color: #039be5">Chi
                  tiết giao dich
                </router-link>
              </td>
            </tr>
            </tbody>
            <tfoot style="height: 50px;">
            <Panigation :dataPage="data_transaction_paginate" @pageChange:index="fetchData"/>
            </tfoot>
          </table>
          <div v-if="data_transaction.length === 0" class="items-center w-full bg-transparent border-collapse"
               style="height: 150px;">
            <i>Không có dữ liệu!</i>
          </div>
          <div v-if="isLoading" class="loading absolute loading_table">
            <img :src="loadingImage" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-main table tbody {
  font-size: 22px;
  font-weight: 500;
}

.cf_service_type_label {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #10b981;
  color: #10b981;
}

.cf_service_type_label_all {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #f97316;
  color: #f97316;
}

.list-config .wrap_service_type_labels {
  display: flex;
  grid-column-gap: 8px;
  flex-wrap: wrap;
  grid-row-gap: 7px;
}

.progress-export {
  height: 100%;
  position: absolute;
  background-color: #01dd2e;
  opacity: 0.5;
  top: 0;
  left: 0;
  transition: 2s;
}
</style>

<script>

import apiClient from '@/services/api.js';
import Alert from "@/components/Alerts/Alert.vue"
import Multiselect from "vue-multiselect";
import {debounce} from 'vue-debounce'
import Panigation from "@/components/Panigation.vue";
import loadingImage from "@/assets/img/loading.gif";
import {toast} from 'vue3-toastify';
import moment from 'moment';

const debounceFn = debounce(callback => callback(), '400ms')

export default {
  data() {
    return {
      alertMessage: '',
      alertType: 'info',
      merchant_name: '',
      amount: null,
      merchant_key: null,
      // service_types: ServiceTypes,
      // service_type_selected: [],
      created_at: '',
      updated_at: '',
      payment_no: null,
      merchantOptions: [],
      merchantLoading: false,
      data_transaction: [],
      data_transaction_paginate: [],
      meta_data: {
        client_ip: '',
        method: '',
        card_brand: '',
        invoice_no: '',
        card_number : '',
        bank_code : ''
      },
      status: {
        value: '',
        label: ''
      },
      formData: {
        card_number: '',
        merchant_key: null,
        date_from: moment().subtract(7, "days").format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
        payment_no: '',
        merchant_name: '',
        amount: '',
      },
      page: null,
      loadingImage,
      isLoading: false,
      progressExport: 0,
      isExporting: false,
      // totalServiceType: ServiceTypes.length
    };
  },
  components: {
    Panigation,
    Multiselect,
    Alert,
  },
  props: {
    //
  },
  created() {
    this.searchMerchant();
    this.fetchData();
  },
  watch: {
    //
  },
  methods: {
    searchMerchant(query) {
      debounceFn(async () => {
        // if (!query || query.trim() === "") {
        //   return;
        // }
        this.merchantLoading = true;
        const self = this;
        try {
          let {data} = await apiClient.get('api/merchant/search', {
            params: {keyword: query}
          })
          self.merchantLoading = false;
          if (data.status) {
            self.merchantOptions = data.data;
          }
        } catch (e) {
          console.log(e);
          toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
            "type": "error",
            "transition": "flip",
            "dangerouslyHTMLString": true
          })
        }
      })
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (page) {
        this.page = page
      }
      try {
        let {data} = await apiClient.get('api/transaction/index', {
          params: {
            page: this.page,
            payment_no: this.formData.payment_no,
            amount: this.formData.amount,
            date_from: this.formData.date_from,
            date_to: this.formData.date_to,
            card_number: this.formData.card_number,
            merchant_key: this.formData.merchant_key?.merchant_key,
            date_type: 1
          }
        });
        if (data.status) {
          this.data_transaction = data.data.data;
          this.data_transaction_paginate = data.data;
          this.data_transaction = this.data_transaction.map(transaction => {
            if (transaction.meta_data ) {
              try {
                transaction.meta_data = JSON.parse(transaction.meta_data);
              } catch (e) {
                transaction.meta_data = {};
              }
            }
            return transaction;
          });
        }
      } catch (e) {
        console.log(e);
        toast(e?.response?.data?.message || "Đã có lỗi xảy ra!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.fetchData();
    },
    async handleDownloadFile() {
      if (this.isExporting) {
        return;
      }
      toast("Yêu cầu đang được xử lý! Vui lòng không rời khỏi màn hình", {
        "type": "warning",
        "transition": "flip",
        "dangerouslyHTMLString": true
      });

      this.isExporting = true;
      this.progressExport = 0;
      const params = {
        page: this.page,
        payment_no: this.formData.payment_no,
        amount: this.formData.amount,
        date_from: this.formData.date_from,
        date_to: this.formData.date_to,
        card_number: this.formData.card_number,
        merchant_key: this.formData.merchant_key?.merchant_key,
        date_type: 1
      }

      try {
        let reponsePushAction = await apiClient.get('api/transaction/export', {params});
        if (reponsePushAction.data.status) {
          const SELF = this;
          let interval = setInterval(function () {
            apiClient.get('api/transaction/export', {
              params: {
                ...params,
                key: reponsePushAction.data.data.key
              }
            })
                .then(data => data.data.data)
                .then(data => {
                  if (data.progress === 100) {
                    clearInterval(interval);
                    SELF.progressExport = 100;
                    toast("Xuất file thành công!", {
                      "type": "success",
                      "transition": "flip",
                      "dangerouslyHTMLString": true
                    });
                    setTimeout(function () {
                      SELF.isExporting = false;
                    }, 3000);

                    window.location.href = (`${process.env.VUE_APP_BACKEND_URL}/storage/exports/${data.file_name}?file_name=Danh sách giao dịch qua cổng.csv`);
                  }
                  SELF.progressExport = data.progress;
                }).catch((e) => {
              console.log(e)
              clearInterval(interval);
              SELF.isExporting = false;
              toast(e?.response?.data?.message || "Xuất file thất bại!", {
                "type": "error",
                "transition": "flip",
                "dangerouslyHTMLString": true
              })
            });
          }, 3000);
        }
      } catch (e) {
        toast(e?.response?.data?.message || "Xuất file thất bại!", {
          "type": "error",
          "transition": "flip",
          "dangerouslyHTMLString": true
        })
        console.log(e);
      }
    },
    maskedCardNumber(cardNumber) {
      if (!cardNumber) return '';
      return cardNumber.replace(/^(\d{6})\d*(\d{4})$/, '$1xxxxxx$2');
    },
    changeNameMethod(method) {
      const methodMapping = {
        WALLET: 'Ví điện tử',
        ATM_CARD: 'Thẻ nội địa',
        COLLECTION: 'Thu hộ',
        CREDIT_CARD: 'Thẻ quốc tế'
      };

      return methodMapping[method] || method;
    },

  },
};
</script>
