export default [
    {
        'name': 'Dashboard',
        'link': '/admin/dashboard',
        'icon': 'fas fa-tv'
    }, {
        'name': 'Quản lý authent merchant',
        'link': '/admin/merchantManager',
        'icon': 'fas fa-user'
    }, {
        'name': 'Công cụ',
        'link': '/admin/tool/merchant-qr',
        'icon': 'fas fa-tools',
        'children': [
            {
                'name': 'Config Merchant QR',
                'link': '/admin/tool/merchant-qr',
                'icon': 'fas fa-sliders-h',
            },
            {
                'name': 'Config VAS',
                'link': '/admin/tool/config-vas',
                'icon': 'fas fa-cogs',
            }
        ]
    }, {
        'name': 'Báo cáo phí',
        'link': '/admin/config-fee',
        'icon': 'fas fa-funnel-dollar',
        'children': [
            {
                'name': 'Config Phí',
                'link': '/admin/config-fee/index',
                'icon': 'fas fa-wrench',
            }, {
                'name': 'Báo cáo phí theo giao dịch',
                'link': '/admin/config-fee/reconcile',
                'icon': 'fas fa-chart-bar',
            }, {
                'name': 'Danh sách giao dịch Cổng',
                'link': '/admin/config-fee/transaction/index',
                'icon': 'fas fa-dot-circle',
            }
        ]
    }, {
        'name': 'Báo cáo giao dịch QR',
        'link': '/admin/va-transaction/index',
        'icon': 'fas fa-qrcode'
    }, {
        'name': 'Thống kê giao dịch Merchant',
        'link': '/admin/report/transaction',
        'icon': 'fas fa-chart-line'
    }, {
        'name': 'Quản lý người dùng',
        'link': '/admin/account',
        'icon': 'fa fa-users',
        'children': [
            {
                'name': 'Danh sách user',
                'link': '/admin/account',
                'icon': 'fa fa-address-book',
            },
            {
                'name': 'Quản lý vai trò',
                'link': '/admin/role',
                'icon': 'fa fa-user-tag'
            }, {
                'name': 'Quản lý quyền',
                'link': '/admin/permission',
                'icon': 'fa fa-user-lock'
            },
        ],
    },
]
